<template>
  <b-row v-if="getFormData.id">
    <b-col
      cols="12"
    >
      <welcome v-if="getFormData.id_com_hr_notification_types === '1'" />
      <baby v-if="getFormData.id_com_hr_notification_types === '2'" />
      <leaving v-if="getFormData.id_com_hr_notification_types === '3'" />
      <promote v-if="getFormData.id_com_hr_notification_types === '4'" />
      <death v-if="getFormData.id_com_hr_notification_types === '5'" />
      <seniority v-if="getFormData.id_com_hr_notification_types === '6'" />
      <b-button
        v-if="!submitStatus"
        variant="primary"
        @click="submitForm"
      >
        Kaydet
      </b-button>
      <b-button
        v-else
        variant="primary"
        disabled
      >
        <b-spinner small />
        Lütfen Bekleyiniz...
      </b-button>
    </b-col>
    <b-col
      cols="12"
      class="mt-3"
    >
      <b-card>
        <b-card-title>Önizleme</b-card-title>
        <WelcomePreview v-if="getFormData.id_com_hr_notification_types === '1'" />
        <BabyPreview v-if="getFormData.id_com_hr_notification_types === '2'" />
        <LeavingPreview v-if="getFormData.id_com_hr_notification_types === '3'" />
        <PromotePreview v-if="getFormData.id_com_hr_notification_types === '4'" />
        <DeathPreview v-if="getFormData.id_com_hr_notification_types === '5'" />
        <SeniorityPreview v-if="getFormData.id_com_hr_notification_types === '6'" />
      </b-card>
    </b-col>
  </b-row>
  <b-row v-else>
    <b-col cols="12">
      <Loading />
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BButton, BCard, BCardTitle, BSpinner,
} from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import Loading from '@/layouts/components/Loading'
// eslint-disable-next-line import/extensions
import Welcome from '@/views/Hr/Notifications/Forms/Welcome'
// eslint-disable-next-line import/extensions
import Baby from '@/views/Hr/Notifications/Forms/Baby'
// eslint-disable-next-line import/extensions
import Leaving from '@/views/Hr/Notifications/Forms/Leaving'
// eslint-disable-next-line import/extensions
import Promote from '@/views/Hr/Notifications/Forms/Promote'
// eslint-disable-next-line import/extensions
import Death from '@/views/Hr/Notifications/Forms/Death'
// eslint-disable-next-line import/extensions
import Seniority from '@/views/Hr/Notifications/Forms/Seniority'
// eslint-disable-next-line import/extensions
import WelcomePreview from '@/views/Hr/Notifications/Preview/Welcome'
// eslint-disable-next-line import/extensions
import BabyPreview from '@/views/Hr/Notifications/Preview/Baby'
// eslint-disable-next-line import/extensions
import LeavingPreview from '@/views/Hr/Notifications/Preview/Leaving'
// eslint-disable-next-line import/extensions
import PromotePreview from '@/views/Hr/Notifications/Preview/Promote'
// eslint-disable-next-line import/extensions
import DeathPreview from '@/views/Hr/Notifications/Preview/Death'
// eslint-disable-next-line import/extensions
import SeniorityPreview from '@/views/Hr/Notifications/Preview/Seniority'

export default {
  name: 'Edit',
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BCardTitle,
    BSpinner,
    Loading,
    Welcome,
    Baby,
    Leaving,
    Promote,
    Death,
    Seniority,
    WelcomePreview,
    BabyPreview,
    LeavingPreview,
    PromotePreview,
    DeathPreview,
    SeniorityPreview,
  },
  data() {
    return {
      submitStatus: false,
      canvasImage: null,
    }
  },
  computed: {
    saveStatus() {
      return this.$store.getters['hrNotifications/getHr_notificationSaveStatus']
    },
    getFormData() {
      return this.$store.getters['hrNotifications/getHr_notification']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.getData()
  },
  methods: {
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    getData() {
      this.$store.dispatch('hrNotifications/hr_notificationView', this.$route.params.id)
    },
    submitForm() {
      this.submitStatus = true
      this.$store.dispatch('hrNotifications/hr_notificationSave', this.getFormData)
    },
  },
}
</script>
